import React from 'react';

export default function Error401() {
  return (
    <React.Fragment>
      <h1>Oops!</h1>
      <p>Inicia sesion para continuar</p>
    </React.Fragment>
  );
}
